/* eslint disable */

// Instead of deprecated @babel/polyfill
import 'core-js/stable';
import 'regenerator-runtime/runtime.js';

import { login, logout, forgot, reset } from './login';
import { signup } from './signup';
import { updateSettings } from './updateSettings';
import {
  addChannelToUser,
  addVideoToUser,
  addVideoListToUser,
  refreshCommentsForVideo,
  refreshSentimentForVideo,
  removeVideosFromUser,
} from './userActions';

// const URL = require('url').URL;

// DOM ELEMENTS
const onDashboard = document.querySelector('#dashboard-actions');
const resizeDataTableColumns = document.querySelector('#videosButton');
const signupForm = document.querySelector('.signup-form');
const loginForm = document.querySelector('.login-form');
const forgotForm = document.querySelector('.forgot-form');
const resetForm = document.querySelector('.reset-form');
const logoutBtn = document.querySelector('#btn-logout');
const updateProfileForm = document.querySelector('#form-updateProfile');
const updatePasswordForm = document.querySelector('#form-updatePassword');

// DASHBOARD - USER ACTIONS
const buttonAddChannel = document.querySelector('#button-addChannel');
const buttonAddChannelVideoList = document.querySelector(
  '#button-addChannelVideoList'
);
const buttonAddVideo = document.querySelector('#button-addVideo');
const submitVideosAddList = document.querySelector('#submit-videosAddList');
const buttonRemoveVideosFromUser = document.querySelector(
  '#button-removeVideosFromUser'
);
const dashboardActionRemove = document.querySelector('#dashboardActionRemove');
const buttonRefreshVideo = document.querySelector('#button-refreshVideo');

// Collapse any collapsable UI element when a click takes place outside of the UI
// to keep the UI from remaining expanded if the action is abandoned.
if (onDashboard) {
  const ui = document.getElementById('dashboard-actions');
  document.addEventListener('click', function (event) {
    if (!ui.contains(event.target)) {
      const elements = ui.querySelectorAll('.collapse-action.collapse.show');
      elements.forEach(function (element) {
        element.classList.remove('show');
      });
    }
  });
}

// DELEGATION
if (signupForm)
  signupForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const name = document.getElementById('floatingName').value;
    const email = document.getElementById('floatingEmail').value;
    const password = document.getElementById('floatingPassword').value;
    const passwordConfirm = document.getElementById(
      'floatingPasswordConfirm'
    ).value;
    signup(name, email, password, passwordConfirm);
  });

if (loginForm)
  loginForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const email = document.getElementById('floatingEmail').value;
    const password = document.getElementById('floatingPassword').value;
    login(email, password);
  });

if (forgotForm)
  forgotForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const email = document.getElementById('floatingEmail').value;
    forgot(email);
  });

if (resetForm)
  resetForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const password = document.getElementById('floatingPassword').value;
    const passwordConfirm = document.getElementById(
      'floatingPasswordConfirm'
    ).value;
    const passwordResetToken =
      document.getElementById('floatingResetToken').value;
    reset(password, passwordConfirm, passwordResetToken);
  });

if (logoutBtn) logoutBtn.addEventListener('click', logout);

if (updateProfileForm)
  updateProfileForm.addEventListener('submit', async (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append('name', document.getElementById('name').value);
    form.append('email', document.getElementById('email').value);
    form.append('photo', document.getElementById('photo').files[0]);
    console.log(form);
    await updateSettings(form, 'profile');
  });

if (updatePasswordForm)
  updatePasswordForm.addEventListener('submit', async (e) => {
    e.preventDefault();
    document.querySelector('.btn--save-password').textContent = 'Updating...';
    const passwordCurrent = document.getElementById('password-current').value;
    const password = document.getElementById('password').value;
    const passwordConfirm = document.getElementById('password-confirm').value;
    await updateSettings(
      { passwordCurrent, password, passwordConfirm },
      'password'
    );
    document.querySelector('.btn--save-password').textContent = 'Save password';
    document.getElementById('password-current').value = '';
    document.getElementById('password').value = '';
    document.getElementById('password-confirm').value = '';
  });

// DASHBOARD ACTIONS
if (buttonAddChannel)
  buttonAddChannel.addEventListener('click', (e) => {
    e.preventDefault();
    const valueSubmitted = document.getElementById('channelIdToAdd').value;
    // console.log(valueSubmitted);
    // ^https?:\/\/(www\.)?youtube\.com\/(channel\/UC[\w-]{21}[AQgw]|(c\/|user\/)?[\w-]+)$
    // const valueParsed = valueSubmitted.split(
    // /(?:https?:\/\/|)(?:www.|)youtube\.com\/(channel\/|user\/|)([a-zA-Z0-9]{1,})/
    // /^https?:\/\/(www\.)?youtube\.com\/(channel\/UC[\w-]{21}[AQgw]|(c\/|user\/)?[\w-]+)$/
    // /(?:https|http):\/\/(?:[\w]+\.)youtube\.com\/(c\/|channel\/|user\/|watch\?v=)?([a-zA-Z0-9\-_@]{1,})/
    // /(?:https?:\/\/)(.+?\.)?(youtube|youtu.be)(\..+?)?(c\/|channel\/|user\/|@)?([a-za-z0-9\-_]{1,})/
    // /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(channel\/|c\/|user\/)|youtu\.be\/)([a-zA-Z0-9_-]+|[a-zA-Z0-9_-]+(channel|c|user|@)[a-zA-Z0-9_-]*)/
    // );
    const valueParsed = valueSubmitted.split(
      /(\/channel\/|\/c\/|\/user\/|\/@)/
    );
    const channelId =
      valueParsed[2] !== undefined
        ? valueParsed[2].split(/[^0-9a-z_-]/i)[0]
        : valueParsed[0];
    // console.log(channelId);
    // const channelId =
    //   valueParsed[2] !== undefined
    //     ? valueParsed[2].split(/[^0-9a-z_\-]/i)[0]
    //     : valueParsed[0];
    addChannelToUser(channelId);
  });

if (buttonAddChannelVideoList)
  buttonAddChannelVideoList.addEventListener('click', (e) => {
    e.preventDefault();
    const table = $('#reportTable').DataTable({
      retrieve: true,
      paging: false,
    });
    const data = table.rows({ selected: true }).data();
    let videoIds = [];
    for (let i = 0; i < data.length; i++) {
      videoIds.push(data[i].snippet.resourceId.videoId);
    }
    addVideoListToUser(videoIds);
  });

if (buttonAddVideo)
  buttonAddVideo.addEventListener('click', (e) => {
    e.preventDefault();
    const valueSubmitted = document.getElementById('videoIdToAdd').value;
    const valueParsed = valueSubmitted.split(
      /(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/
    );
    const videoId =
      valueParsed[2] !== undefined
        ? valueParsed[2].split(/[^0-9a-z_\-]/i)[0]
        : valueParsed[0];
    addVideoToUser(videoId);
  });

if (submitVideosAddList)
  submitVideosAddList.addEventListener('click', (e) => {
    e.preventDefault();
    const videoList = document.getElementById('input-videosAddList').value;
    const videos = videoList.split('\n');
    const videoIds = videos.map(function (video) {
      const valueParsed = video.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      return valueParsed[2] !== undefined
        ? valueParsed[2].split(/[^0-9a-z_\-]/i)[0]
        : valueParsed[0];
    });
    // console.log(videoIds);
    addVideoListToUser(videoIds);
  });

if (dashboardActionRemove)
  dashboardActionRemove.addEventListener('click', (e) => {
    e.preventDefault();
    const table = $('#reportTable').DataTable({
      retrieve: true,
      paging: false,
    });
    const count = table.rows({ selected: true }).count();
    const data = table.rows({ selected: true }).data();
    let videoIds = [];
    for (let i = 0; i < data.length; i++) {
      videoIds.push(data[i]['videoId']);
    }
    let message = 'Click "OK" to confirm removal of ' + count + ' video';
    message = count > 1 ? message + 's.' : message + '.';
    document.getElementById('collapseActionRemove-Message').innerHTML = message;
    document.getElementById('collapseActionRemove-Selected').value =
      JSON.stringify(videoIds);
  });

if (buttonRemoveVideosFromUser)
  buttonRemoveVideosFromUser.addEventListener('click', (e) => {
    e.preventDefault();
    const videoIds = JSON.parse(
      document.getElementById('collapseActionRemove-Selected').value
    );
    removeVideosFromUser(videoIds);
  });

if (buttonRefreshVideo)
  buttonRefreshVideo.addEventListener('click', (e) => {
    e.preventDefault();
    const videoId = document.getElementById('videoIdToRefresh').value;
    addVideoToUser(videoId);
  });

// if (buttonRefreshVideoComments)
//   buttonRefreshVideoComments.addEventListener('click', (e) => {
//     e.preventDefault();
//     const videoId = document.getElementById('videoIdToRefreshComments').value;
//     refreshCommentsForVideo(videoId);
//   });

// if (buttonRefreshVideoSentiment)
//   buttonRefreshVideoSentiment.addEventListener('click', (e) => {
//     e.preventDefault();
//     const videoId = document.getElementById('videoIdToRefreshSentiment').value;
//     refreshSentimentForVideo(videoId);
//   });
