import { showAlert } from './alerts';

export const addChannelToUser = async (channelId) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/channels/${channelId}/retrieve`,
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Channel added successfully!');
      window.setTimeout(() => {
        location.assign(`/channels/preview/${channelId}`);
        // location.reload();
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const addVideoToUser = async (videoId) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/videos/${videoId}/retrieve`,
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Video added successfully!');
      window.setTimeout(() => {
        // location.assign('/dashboard');
        location.reload();
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const addVideoListToUser = async (videoIds) => {
  try {
    const res = await axios({
      method: 'POST',
      url: `/api/v1/videos/retrieve`,
      data: {
        videoIds,
      },
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Your list of videos has been added successfully!');
      window.setTimeout(() => {
        location.assign('/dashboard');
        // location.reload();
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const removeVideosFromUser = async (videoIds) => {
  console.log('useractions:', videoIds);
  try {
    const res = await axios({
      method: 'POST',
      url: `/api/v1/videos/remove`,
      data: {
        videoIds,
      },
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Videos removed successfully!');
      window.setTimeout(() => {
        // location.assign('/dashboard');
        location.reload();
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

// export const refreshCommentsForVideo = async (videoId) => {
//   try {
//     const res = await axios({
//       method: 'PATCH',
//       url: `/api/v1/comments/${videoId}/retrieve`,
//     });
//
//     if (res.data.status === 'success') {
//       showAlert('success', 'Comments refreshed successfully!');
//       window.setTimeout(() => {
//         // location.assign('/dashboard');
//         location.reload();
//       }, 1500);
//     }
//   } catch (err) {
//     showAlert('error', err.response.data.message);
//   }
// };

// export const refreshSentimentForVideo = async (videoId) => {
//   try {
//     const res = await axios({
//       method: 'GET',
//       url: `/api/v1/videos/${videoId}/sentiment`,
//     });
//
//     if (res.data.status === 'success') {
//       showAlert('success', 'Sentiment refreshed successfully!');
//       window.setTimeout(() => {
//         // location.assign('/dashboard');
//         location.reload();
//       }, 1500);
//     }
//   } catch (err) {
//     showAlert('error', err.response.data.message);
//   }
// };
